import React from 'react'
import { FormattedMessage } from 'react-intl';
import { Link } from "gatsby";


import PasteCode from '../../assets/paste-code.png';

import Tradedeoubler from "../../assets/tradedoubler-color-logo.svg";
import Webgains from "../../assets/webgain-color-tr.png";
import Partnerize from "../../assets/partnerize-color.svg";
import Awin from "../../assets/awin-black.png"
import Rakuten from "../../assets/rakouten-color.svg"
import Affiliate from "../../assets/affiliate-color.svg";
import Impact from "../../assets/impact-color.svg";
import Gtm from "../../assets/integration-gtm.png";

import styles from "../../styles/info-site/Integration.module.css";

const partners = [
  {
    id: 'Awin',
    img: Awin
  },
  {
    id: 'Rakuten',
    img: Rakuten
  },
  {
    id: 'Impact',
    img: Impact
  },
  {
    id: 'Tradedeoubler',
    img: Tradedeoubler
  },
  {
    id: 'Affiliate',
    img: Affiliate
  },
  {
    id: 'Partnerize',
    img: Partnerize
  },
  {
    id: 'Webgains',
    img: Webgains
  },
]

function Integration({ title, items, contact, subtitle }) { 
  return (
    <div className={styles.Integration}>
      <div className={styles.IntegrationHeader}>
        <h2 className={styles.IntegrationTitle}><FormattedMessage id={title} /></h2>
        <p><FormattedMessage id={subtitle} /></p>
        <a href="mailto:sales@tyviso.com"><FormattedMessage id={contact} /></a>
      </div>
      <div className={styles.light}>
        <div className={styles.IntegrationList}>
          <div className={`${styles.IntegrationListItem} ${styles.IntegrationListItemDesc}`}>
            <h3>Option 1</h3>
            <h4>Embed Via tag Manager Manually</h4>
            <p>{`Open your tag manager and set the following script to fire unconditionally in the <head>. Tyviso does the rest via URL based firing.`}</p>
          </div>
          <div className={styles.IntegrationListItem}>
            <div className={styles.IntegrationListItemCode}>
              <img
                src={PasteCode}
                alt=""
              />
              <p>{`<script src="https://sitetag.tyviso.com/XXXXXX.js"></script>`}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.IntegrationList}>
        <div className={`${styles.IntegrationListItem} ${styles.IntegrationListItemDesc}`}>
          <h3>Option 2</h3>
          <h4>Embed Via tag Manager plugin</h4>
          <p>{`Select our plugin from the GTM plugin store and just enter your customer ID.`}</p>
        </div>
        <div className={styles.IntegrationListItem}>
          <div className={styles.IntegrationListItemGtm}>
            <img src={Gtm} alt="tyviso gtm integration" />
          </div>
        </div>
      </div>
      <div className={styles.light}>
        <div className={styles.IntegrationList}>
          <div className={`${styles.IntegrationListItem} ${styles.IntegrationListItemDesc}`}>
            <h3>Option 3</h3>
            <h4>Embed Via Affiliate Network</h4>
            <p>{`We have one click activation with many of the common affiliate networks.`}</p>
          </div>
          <div className={styles.IntegrationListItem}>
            <div className={styles.IntegrationListItemPartners}>
              {
                partners.map(({ id, img }) => (
                  <div className={styles.IntegrationListItemPartnersNetwork} key={id}>
                    <img src={img} alt={id} />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.IntegrationList}>
        <div className={`${styles.IntegrationListItem} ${styles.IntegrationListItemDesc}`}>
          <h3>Option 4</h3>
          <h4>Integrate natively in-app</h4>
          <p>{`Use one of our APIs to integrate into your app and make it look like you did the work`}</p>
        </div>
        <div className={styles.IntegrationListItem}>
          <div className={styles.IntegrationListItemCode}>
            <Link to="/api">View our APIs</Link>
          </div>
        </div>
      </div>
      {
        items?.length && (
          <div className={styles.light}>
            <div className={styles.IntegrationList}>
              {
                items.map(({
                  id,
                  title: cardTitle,
                  value,
                  url,
                  description
                }) => (
                  <div key={id.toLowerCase()} className={styles.IntegrationListItem}>
                    <Link to={url}>
                      <h3><FormattedMessage id={cardTitle} /></h3>
                      <h4>{value}</h4>
                      <p><FormattedMessage id={description} /></p>
                    </Link>
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </div>
  )
};

export default Integration;